<template>
  <div class="container-fluid p-0">
    <div class="container pt-2 pb-5">
      <swiper
        :slides-per-view="1"
        :pagination="{ dynamicBullets: true, clickable: true }"
        :loop="true"
        :autoplay="{
          delay: 4000,
          disableOnInteraction: false,
        }"
        class="mySwiper"
      >
        <swiper-slide>
          <img src="@/assets/img/slider/01.jpg" alt="" srcset="" />
        </swiper-slide>
        <swiper-slide>
          <img src="@/assets/img/slider/02.jpg" alt="" srcset="" />
        </swiper-slide>
        <swiper-slide>
          <img src="@/assets/img/slider/03.jpg" alt="" srcset="" />
        </swiper-slide>
        <swiper-slide>
          <img src="@/assets/img/slider/04.jpg" alt="" srcset="" />
        </swiper-slide>
        <swiper-slide>
          <img src="@/assets/img/slider/05.jpg" alt="" srcset="" />
        </swiper-slide>
        <swiper-slide>
          <img src="@/assets/img/slider/06.jpg" alt="" srcset="" />
        </swiper-slide>
      </swiper>
      <section class="row py-5">
        <div class="col-lg-6 col-md-12 left" data-aos="fade-up">
          <small> Leading the way in Building and civil Construction </small>
          <h1>
            Committed to delivery high qulity construction projects and innovate
            design!
          </h1>
          <p>
            Yet those that embrace change are thriving,building bigger, batter,
            faster, and stronger products than ever before. You are helping to
            lead the charge,we can you build on your past and prepare future
            <br /><br />
            The world changing faster than ever before,Vs Constructions are
            threated as technology disrupts and software.
          </p>
          <div class="row my-3">
            <div class="col-lg-6 col-md-12">
              <ul>
                <li>
                  <i class="bx bxs-check-circle"></i> &nbsp;WAREHOUSE & STORAGE
                </li>
                <li><i class="bx bxs-check-circle"></i> &nbsp; CLADDING</li>
                <li>
                  <i class="bx bxs-check-circle"></i> &nbsp; INTERNAL MEZZANINE
                  FLOOR DECKS
                </li>
                <li>
                  <i class="bx bxs-check-circle"></i> &nbsp; STRUCTURE - FACTORY
                </li>
                <li>
                  <i class="bx bxs-check-circle"></i> &nbsp; BOOM TRUCK
                  TRANSPORT
                </li>
              </ul>
            </div>
            <div class="col-lg-6 col-md-12">
              <ul>
                <li>
                  <i class="bx bxs-check-circle"></i> &nbsp; Fabrication and
                  erection of all steel structures and buildings
                </li>

                <li><i class="bx bxs-check-circle"></i> &nbsp; ROOFING</li>
                <li>
                  <i class="bx bxs-check-circle"></i> &nbsp; ROOF PLUMBING
                </li>
                <li>
                  <i class="bx bxs-check-circle"></i> &nbsp; HIRING - CRANE
                </li>
              </ul>
            </div>
          </div>
          <router-link to="/projects" class="btn"
            ><i class="bx bxs-right-arrow-circle"></i>&nbsp;Our
            Projects</router-link
          >
        </div>
        <div class="col-lg-6 col-md-12 right" data-aos="fade-down">
          <div class="container py-5">
            <img
              src="@/assets/img/helmet.jpg"
              srcset="@/assets/img/helmet.jpg"
              class="sideImg"
            />
          </div>
        </div>
      </section>
      <section class="pt-5 mt-5 wrapper">
        <small> SPECIALIZED AREAS IN CONSTRUCTION </small>
        <h3>High Quality construction solutions for industries</h3>

        <div class="container">
          <div class="row px-3" data-aos="fade-up">
            <CardComponent
              v-for="solution in state.solutions"
              :key="solution.id"
              :heading="solution.heading"
              :details="solution.details"
              data-aos="fade-down"
              data-aos-delay="300"
            />
          </div>
        </div>
      </section>
      <section class="recommendations pt-5 mt-5">
        <h3>Customer Satisfaction</h3>
        <section class="row">
          <div class="col-lg-4 col-sm-12 col-md-6 mb-4">
            <img
              src="@/assets/img/recommendations/1.jpg"
              alt=""
              data-aos="fade-up"
            />
          </div>
          <div class="col-lg-4 col-sm-12 col-md-6 mb-4">
            <img
              src="@/assets/img/recommendations/2.jpg"
              alt=""
              data-aos="fade-down"
            />
          </div>
          <div class="col-lg-4 col-sm-12 col-md-6 mb-4">
            <img
              src="@/assets/img/recommendations/3.jpg"
              alt=""
              data-aos="fade-up"
            />
          </div>
        </section>
      </section>
      <section class="projects py-5">
        <small>We work with global industries </small>
        <h4>featured Projects</h4>
        <div class="row">
          <div
            class="col-lg-3 col-sm-12 col-md-6 mb-4"
            v-for="(project, key) in state.recentProjects"
            :key="key"
          >
            <ProjectCard :project="project" />
          </div>
        </div>
        <router-link to="/projects" class="btn">
          <i class="bx bx-right-arrow-circle"></i> &nbsp;explore
          all</router-link
        >
      </section>
    </div>
  </div>
</template>

<script>
import CardComponent from "@/components/CardComponent.vue";
import ProjectCard from "@/components/ProjectCard.vue";
import projects from "@/assets/data/projects.json";
import { reactive } from "vue";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/css";
import "swiper/css/pagination";

import SwiperCore, { Autoplay, Pagination } from "swiper/core";

// install Swiper modules
SwiperCore.use([Autoplay, Pagination]);

export default {
  name: "HomeView",
  setup() {
    const state = reactive({
      recentProjects: [],
      solutions: [
        {
          id: 1,
          heading: "FACTORY BUILDINGS",
          details:
            "Design to specification, fabrication, painting & erection of medium or wide span portal framed steel Structured Buildings. Power Floating, Concrete Flooring, Zn-Alum Roofing, Wall Cladding, Roof Insulation Systems etc., conforming to BOI and ICTAD regulations and standards. ",
        },
        {
          id: 2,
          heading: "WAREHOUSE & STORAGE BUILDINGS",
          details:
            "Design to Specification, Fabricating, Painting, & Erection of Clear Span Wider Framed Buildings ",
        },
        {
          id: 3,
          heading: "ROOFING, CLADDING & ROOF PLUMBING",
          details:
            "Installation of any type of Steel Roofing and Cladding, including Insulation, Ventilation, Day Light Panels, Fascia, Monitor Roofs, Canopies, Louvers, Eave & Valley Gutters etc. ",
        },
        {
          id: 4,
          heading: "INTERNAL MEZZANINE FLOOR DECKSS",
          details:
            "Installation of any type of Steel Roofing and Cladding, including Insulation, Ventilation, Day Light Panels, Fascia, Monitor Roofs, Canopies, Louvers, Eave & Valley Gutters etc. ",
        },
      ],
    });

    return { state };
  },
  created() {
    for (let i = 1; i <= 4; i++) {
      this.state.recentProjects.push(projects[projects.length - i]);
    }
  },
  mounted() {},
  components: {
    CardComponent,
    ProjectCard,
    Swiper,
    SwiperSlide,
  },
};
</script>

<style lang="scss" scoped>
.sideImg {
  width: 100%;
  max-height: 55vh;
  position: relative;
  top: 0;  
}
.left {
  small {
    color: var(--color-secondary);
    font-weight: 600;
  }
  h1 {
    font-weight: 700;
    color: var(--color-primary);
    line-height: 3.5rem;
    text-transform: capitalize;
    margin: 1rem 0;
  }
  p {
    color: var(--footer-color-secondary);
  }
  ul {
    list-style: none;
    display: block;
    li {
      margin: 10px 0;
      color: var(--color-primary);
      text-transform: capitalize;
      font-weight: 600;
      i {
        color: var(--color-secondary);
        font-weight: 600;
      }
    }
  }
  .btn {
    color: #fff;
    background: var(--color-primary);
    border-radius: 0;
    padding: 0.5rem;
    &:hover {
      background: var(--color-secondary);
      transition: all 0.5s ease-in-out;
      color: var(--color-primary);
    }
  }
}
.right {
  .card {
    width: 100%;
    border: 0;
    text-align: right;
    h1 {
      color: var(--color-secondary);
    }
    span {
      color: var(--footer-color-secondary);
      text-transform: capitalize;
      font-size: 12px;
    }
  }
}

.wrapper {
  background: #f4f4f7;
  text-align: center;
  overflow: hidden;
  small {
    font-size: 1.2rem;
    font-weight: 700;
    color: var(--color-secondary);
  }
  h3,
  h2 {
    font-weight: 700;
    color: var(--color-primary);
    text-transform: capitalize;
  }
  .vedioSection {
    background: var(--color-secondary);
    .left {
      display: flex;
      align-items: center;
    }
    h3 {
      text-align: left;
      color: #ffffff;
      text-transform: capitalize;
    }
    p {
      text-align: left;
      margin: 2rem 0;
      color: #ffffff;
      font-weight: 600;
      &.bottom {
        font-weight: 400;
      }
    }
    .card {
      cursor: pointer;
      width: 10rem;
      overflow: hidden;
      padding: 0;
      border: 0;
      border-radius: 0;
      margin: 5px;
      h5 {
        font-size: 15px;
        text-align: center;
        text-transform: capitalize;
      }
      i {
        font-size: 65px;
      }
      &:hover {
        transition: all 0.4s ease-in-out;
        background: var(--footer-color-secondary);
        i {
          color: #ffffff;
        }
        h5 {
          color: #ffffff;
        }
      }
    }

    @media screen and (max-width: 991px) {
      h3 {
        text-align: center;
      }
      p {
        text-align: center;
        &.bottom {
          font-weight: 400;
        }
      }
      .card {
        margin: 5px auto;

        i {
          font-size: 50px;
        }
      }
    }
    @media screen and (max-width: 425px) {
      .card {
        width: 90%;
        margin: 5px auto;

        i {
          font-size: 50px;
        }
      }
    }
  }

  @media screen and (max-width: 425px) {
    small {
      font-size: 0.8rem;
      margin-bottom: 1rem;
    }
  }
}
.projects {
  small {
    color: var(--color-secondary);
    margin-bottom: 1rem;
    font-weight: 700;
  }
  h4 {
    text-transform: capitalize;
    color: var(--color-primary);
    font-weight: 700;
    margin-bottom: 2rem;
  }
  .btn {
    width: 200px;
    display: block;
    margin: 0 auto;
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    border-radius: 0px;

    &:hover {
      transition: all 0.2s ease-in-out;
      color: #fff;
      background: var(--color-primary);
    }
  }
}
.swiper {
  width: 100%;
  background-size: cover;
  background-position: center;
  z-index: 1;
}
.swiper-slide {
  width: 100%;
  height: 100%;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.recommendations {
  width: 100%;
  small {
    color: var(--color-secondary);
    margin-bottom: 1rem;
    font-weight: 700;
  }
  h3 {
    text-transform: capitalize;
    color: var(--color-primary);
    font-weight: 700;
    margin-bottom: 2rem;
  }
  img {
    width: 100%;
  }
}
</style>
