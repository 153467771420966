<template>
  <div class="wrapper">
    <h5>Projects</h5>
    <ul class="list-group">
      <router-link
        :to="`/projects?id=${item.id}`"
        class="list-group-item my-1 shadow"
        @click="getProject(item.id)"
        v-for="(item, key) in state.list"
        :key="key"
      >
        <i class="bx bxs-right-arrow-circle"></i> &nbsp; {{ item.header }}
      </router-link>
    </ul>
  </div>
</template>

<script>
import projects from "@/assets/data/projects.json";
import { reactive } from "vue";
export default {
  setup() {
    const state = reactive({
      list: [],
    });
    return { state };
  },
  created() {
    this.getProject(this.$route.query.id);

    for (let i = 1; i <= projects.length; i++) {
      this.state.list.push({
        id: projects[projects.length - i].id,
        header: projects[projects.length - i].project,
        year: projects[projects.length - i].Completion,
      });
    }
  },

  methods: {
    getProject(id = projects.length) {
      this.$emit("project", id);
    },
  },
};
</script>

<style lang="scss" scoped>
*::-webkit-scrollbar {
  width: 0.5vw;
}

*::-webkit-scrollbar-track {
  background: var(--color-primary);
}

*::-webkit-scrollbar-thumb {
  background: var(--color-secondary);
}

.wrapper {
  width: 100%;
  height: 600px;
  overflow: hidden;
  padding: 1rem 0;
  border-bottom: 3px solid var(--color-secondary);

  h5 {
    color: var(--color-primary);
    font-weight: bold;
  }
  ul {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    a {
      text-decoration: none;
      font-size: 12px;
      color: var(--color-primary);
      font-weight: bold;
      border: none;
      transition: all 0.5s ease-in-out;
      padding: 1rem 0.5rem;

      i {
        color: var(--color-primary);
      }
      &.active,
      &:hover {
        background-color: var(--color-secondary);

        color: #fff;
        i {
          color: #fff;
        }
      }
    }
  }
}
</style>
