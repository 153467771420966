<template>
  <div class="container-fluid">
    <div class="container-fluid imageRow">
      <div class="container py-5">
        <div class="row" data-aos="fade-up">
          <div class="col-lg-6 col-sm-12">
            <small> Bigger,better,faster and stronger projects </small>
            <h1>
              Leading the way in building <br />
              & civil construction!
            </h1>
          </div>
          <div class="col-lg-6 col-sm-12 right">
            <p>
              The world is changing faster than ever before. Vs constructions
              are threated as technology disrupts and software.Yet those embrace
              change are thriving,build bigger,better,faster & stronger products
              than ever before, we help you build on your past and prepare
              future
            </p>
          </div>
        </div>
        <div class="row my-5">
          <div class="col-lg-3 col-md-6">
            <div
              class="card"
              data-aos="zoom-in"
              data-aos-easing="ease-in-sine"
              data-aos-duration="500"
            >
              <div class="card-body">
                <i class="bx bxs-hard-hat"></i>
                <h5 class="card-title">Profesional staff</h5>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div
              class="card"
              data-aos="zoom-in"
              data-aos-easing="ease-in-sine"
              data-aos-duration="500"
            >
              <div class="card-body">
                <i class="bx bx-happy"></i>
                <h5 class="card-title">100% satisfaction</h5>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div
              class="card"
              data-aos="zoom-in"
              data-aos-easing="ease-in-sine"
              data-aos-duration="500"
            >
              <div class="card-body">
                <i class="bx bx-test-tube"></i>
                <h5 class="card-title">Accurate Testing</h5>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div
              class="card"
              data-aos="zoom-in"
              data-aos-easing="ease-in-sine"
              data-aos-duration="500"
            >
              <div class="card-body">
                <i class="bx bxs-badge-dollar"></i>
                <h5 class="card-title">Transparent price</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container py-5">
      <div class="row">
        <div class="col-lg-4 col-sm-12" data-aos="fade-up">
          <div class="contact-details py-5 px-3">
            <h4>Contact info</h4>
            <ul>
              <li>
                <h6>Our Locations</h6>
                <a
                  href="https://www.google.com/maps/dir//6.784860,+80.05842/@6.7744159,80.1817919,75222m/data=!3m1!1e3!4m6!4m5!1m0!1m3!2m2!1d80.05842!2d6.78486"
                  target="_blank"
                  >No. 503, Moragahena, Millewa
                </a>
                <br />
                <br />
                <a href="#"
                  >No.23 Thilinapura, Thalangala, Gonapola, Sri Lanka.
                </a>
              </li>
              <li>
                <h6>Quick Contact</h6>
                Email:
                <a
                  href="mailto:arsconstructionspvtltd@gmail.com "
                  target="_blank"
                >
                  arsconstructionspvtltd@gmail.com
                </a>
                <br />
                <br />
                cal us : &nbsp; <br />

                <a href="tel:+94777707689">+94 77 7707 689 </a>
                <br />
                <a href="tel:+94711423759">+94 76 599 1481 </a>
              </li>
              <li>
                <h6>Opening Hours</h6>
                <p>Mon-Fir: 8am - 5pm</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-8 col-sm-12" data-aos="fade-down">
          <div class="form-wrapper py-5 px-3">
            <h2 class="mb-4">Contact us</h2>
            <div class="alert alert-success" v-if="responseM">
              {{ responseM }}
            </div>
            <div class="row">
              <div class="col-lg-6 col-sm-12 my-2">
                <input
                  type="text"
                  placeholder="First Name"
                  class="form-control"
                  v-model="state.firstName"
                />
                <span class="text-danger" v-if="v$.firstName.$error">{{
                  v$.firstName.$errors[0].$message
                }}</span>
              </div>
              <div class="col-lg-6 col-sm-12 my-2">
                <input
                  type="text"
                  placeholder="Last Name"
                  class="form-control"
                  v-model="state.lastName"
                />
                <span class="text-danger" v-if="v$.lastName.$error">{{
                  v$.lastName.$errors[0].$message
                }}</span>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-lg-12 col-sm-12">
                <input
                  type="email"
                  placeholder="Email"
                  class="form-control"
                  v-model="state.email"
                />
                <span class="text-danger" v-if="v$.email.$error">{{
                  v$.email.$errors[0].$message
                }}</span>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-lg-12 col-sm-12">
                <input
                  type="text"
                  placeholder="Subject"
                  class="form-control"
                  v-model="state.subject"
                />
                <span class="text-danger" v-if="v$.subject.$error">{{
                  v$.subject.$errors[0].$message
                }}</span>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-lg-12 col-sm-12">
                <textarea
                  class="form-control"
                  v-model="state.message"
                  placeholder="Message"
                ></textarea>
                <span class="text-danger" v-if="v$.message.$error">{{
                  v$.message.$errors[0].$message
                }}</span>
              </div>
            </div>
            <div class="row my-2">
              <button class="btn" @click.prevent="handleSubmit">
                Submit &NonBreakingSpace;<i class="bx bx-send"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d829.2599719797757!2d80.05776906950643!3d6.7848579312888955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae24dd195178411%3A0xd17fe6724d80f88!2sARS%20Constructions%20(Pvt)%20Ltd!5e1!3m2!1sen!2slk!4v1687768861555!5m2!1sen!2slk"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            class="google-maps"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
import axios from "axios";

export default {
  setup() {
    const state = reactive({
      firstName: "",
      lastName: "",
      subject: "",
      email: "",
      message: "",
    });
    const rules = computed(() => {
      return {
        firstName: {
          required: helpers.withMessage("First Name is required", required),
        },
        lastName: {
          required: helpers.withMessage("Last Name is required", required),
        },
        subject: {
          required: helpers.withMessage("Subject is required", required),
        },
        email: {
          required: helpers.withMessage("Email is required", required),
          email: helpers.withMessage("Type a valid email address", email),
        },
        message: {
          required: helpers.withMessage("Message is required", required),
        },
      };
    });
    const v$ = useVuelidate(rules, state);
    return { state, v$ };
  },
  data() {
    return { responseM: "" };
  },
  methods: {
    handleSubmit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const formData = new FormData();

        formData.append("fname", this.state.firstName);
        formData.append("lname", this.state.lastName);
        formData.append("subject", this.state.subject);
        formData.append("mail", this.state.email);
        formData.append("massage", this.state.message);
        axios
          .post("https://images.arsconstructions.lk/", formData)
          .then(() => {
            this.responseM = "message sended";
          })
          .catch(() => {
            this.responseM = "message sended";
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  padding: 0;
  &.imageRow {
    background: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)),
      url("@/assets/img/contact/contact.jpg");
    background-size: cover;
  }
  small {
    color: var(--color-secondary);
    text-transform: capitalize;
    line-height: 3rem;
  }
  font-weight: 700;
  h1 {
    color: #fff;
    font-weight: 800;
  }
  .right {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      color: #fff;
      font-weight: 500;
      text-align: left;
      padding: 2rem;
    }
  }
  .card {
    width: 100%;
    cursor: pointer;
    overflow: hidden;
    padding: 0;
    border: 0;
    border: 2px solid #fff;
    border-radius: 0;
    margin: 5px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    h5 {
      font-size: 15px;
      text-transform: capitalize;
      color: #ffffff;
    }
    i {
      font-size: 65px;
      color: var(--color-secondary);
      margin: 1rem 0;
    }
    &:hover {
      transition: all 0.4s ease-in-out;
      background: var(--color-secondary);
      i {
        color: #ffffff;
      }
      h5 {
        color: #ffffff;
      }
    }
  }
}
.contact-details {
  position: relative;
  top: -8rem;
  left: 0;
  width: 100%;
  height: auto;
  background-color: var(--color-primary);
  color: #fff;
  text-transform: capitalize;
  h4 {
    font-weight: 700;
    margin-bottom: 2rem;
  }
  ul {
    list-style: none;
    li {
      width: 100%;
      padding: 1rem 0;
      margin: 5px 0;
      &:nth-child(2) {
        a {
          text-transform: lowercase;
        }
      }
      a {
        text-decoration: underline;
        color: #fff;
        font-weight: 500;
      }
      h6 {
        font-weight: 600;
        margin-bottom: 1rem;
      }
    }
  }
  @media screen and (max-width: 768px) {
    font-size: 15px;
    ul {
      li {
        a {
          font-size: 12px;
        }
      }
    }
  }
}
.form-wrapper {
  position: relative;
  top: -8rem;
  left: 0;
  width: 95%;
  display: block;
  margin: 0 auto;
  background: #fff;
  textarea {
    min-height: 200px;
  }
  h2 {
    color: var(--color-primary);
    text-align: center;
    font-weight: bold;
  }
  .form-control {
    padding: 0.8rem 2rem;
    border: 1px solid rgb(238, 236, 236);
    border-radius: 0;
    &::placeholder {
      color: var(--color-primary);
    }
    &:focus {
      border-color: rgba(8, 30, 69, 0.8);
      box-shadow: none;
      outline: 0 none;
    }
  }
  .btn {
    width: 200px;
    display: block;
    margin: 0 auto;
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    border-radius: 0px;

    &:hover {
      transition: all 0.2s ease-in-out;
      color: #fff;
      background: var(--color-primary);
    }
  }

  @media screen and (max-width: 537px) {
    width: 100%;
  }
}

.google-maps{
  width: 100%;
  height: 75vh;
}
</style>
