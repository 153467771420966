<template>
  <HeaderPart />
  <NavBar />
  <router-view />
  <FooterPart />
</template>

<script>
import AOS from "aos";
export default {
  mounted() {
    AOS.init();
  },
};
</script>

<style lang="scss">
:root {
  --footer-color-secondary: #081e45;
  --color-primary: #0b2653;
  --color-secondary: #fdb900;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
body {
  overflow-x: hidden;
}
</style>
