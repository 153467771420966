<template>
  <div class="container-fluid">
    <div class="banner">
      <h1 data-aos="fade-down">About Us</h1>
    </div>
    <div class="container">
      <h1 data-aos="fade-up" data-aos-delay="300">Overview</h1>
      <p data-aos="fade-up" data-aos-delay="600">
        Our airfreight staff attaches great importance to customizing the
        booking process for our customers.Thats's why we strive to find the air
        freight solution that best suits you needs.We'il ask you when the
        freight is available, what the required delivery date is, and if there's
        potential to save on time or cost. Your answer to these and other
        questions help us decide if you should book the air freight as direct.
      </p>
      <div class="row my-5">
        <div class="col-lg-4 col-md-6 col-sm-12 mb-2">
          <div class="card py-4 shadow-sm"
              data-aos="zoom-in"
              data-aos-easing="ease-in-sine"
              data-aos-duration="500">
            <h5><i class="bx bx-check"></i> &nbsp; Quality Control System</h5>
            <p>
              We enhance our industry operations by relieving you of the worries
              associates with freight forwarding
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 mb-2">
          <div class="card py-4 shadow-sm" 
              data-aos="zoom-in"
              data-aos-easing="ease-in-sine"
              data-aos-duration="500">
            <h5><i class="bx bx-check"></i> &nbsp; High Profesional Staff</h5>
            <p>
              We enhance our industry operations by relieving you of the worries
              associates with freight forwarding
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 mb-2">
          <div class="card py-4 shadow-sm" 
              data-aos="zoom-in"
              data-aos-easing="ease-in-sine"
              data-aos-duration="500">
            <h5><i class="bx bx-check"></i> &nbsp; Quality Control System</h5>
            <p>
              We enhance our industry operations by relieving you of the worries
              associates with freight forwarding
            </p>
          </div>
        </div>
      </div>
      <div class="row my-5 cardAbout">
        <div class="col-lg-6 col-sm-12 image" data-aos="flip-left"></div>
        <div class="col-lg-6 col-sm-12" data-aos="flip-right">
          <h1>OUR VISION</h1>
          <p>
            TO REACH THE PINNACLE OF ENGINEERING EXCELLENCE BY WAY OF MEANINGFUL
            CONTRIBUTIONS TO THE CONSTRUCTION INDUSTRY, COMBINING HI-TECH
            EXPERTISE WITH HANDS ON EXPERIENCE ACQUIRED BY OUR PROFESSIONALLY
            QUALIFIED TEAM, THROUGH YEARS OF DEDICATED SERVICE
          </p>
          <h1>OUR MISSION</h1>
          <p>
            TO BE PLACED ALONGSIDE THE LEADERS IN THE CONSTRUCTION INDUSTRY AND
            RELENTLESSLY STRIVE TO PROVIDE COMPREHENSIVE AND QUALITATIVE
            ENGINEERING SOLUTIONS TO LOCAL AND FOREIGN INVESTORS.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.container-fluid {
  padding: 0;
  .banner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 60vh;
    background: linear-gradient(
        to left,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.3)
      ),
      url("@/assets/img/aboutUs/about.jpg");

    background-size: cover;
    background-position: center center;
    h1 {
      font-size: 60px;
      color: #fff;
    }
    @media screen and (max-width: 425px) {
      h1 {
        font-size: 30px;
        color: #fff;
      }
    }
  }
  .container {
    h1 {
      color: var(--color-primary);
      font-weight: bold;
      margin: 2rem;
    }
    p {
      padding: 0 2rem;
      color: var(--footer-color-secondary);
    }
    .card {
      width: 95%;
      cursor: pointer;
      border: 1px solid var(--color-primary);
      h5 {
        color: var(--color-primary);
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          font-size: 2rem;
        }
      }
      p {
        color: var(--footer-color-secondary);
        font-size: 13px;
        margin: 0.5rem 0 0 0;
        text-align: center;
      }
      &:hover {
        background-color: var(--footer-color-secondary);
        background-color: var(--color-secondary);
        transition: all 0.2s ease-in-out;
        h5,
        p {
          color: #fff;

          font-weight: bold;
        }
      }
    }
    .cardAbout {
      min-height: 60vh;
      height: auto;
      border-radius: 20px;
      .image {
        background: url("@/assets/img/aboutUs/banner.jpg");
        background-size: cover;
        background-position: center center;
      }
    }
    @media screen and (max-width: 991px) {
      .cardAbout {
        min-height: 60vh;
        height: auto;
        border-radius: 20px;
        .image {
          height: 50vh;
          background: url("@/assets/img/aboutUs/banner.jpg");
          background-size: cover;
          background-position: center center;
        }
      }
    }
    @media screen and (max-width: 425px) {
      p {
        font-size: 10px;
        padding: 0 1rem;
      }
    }
  }
}
</style>
