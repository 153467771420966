<template>
  <footer class="container-fluid">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 py-5 footer-data">
          <h5>ARS Constructions</h5>
          <p>
            A leading developer 'A' grade commercial, industriyal projects in
            Sri Lanka. Since it's foundation the company has ddoubled its gain
            step by step, with the team
          </p>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 py-5 footer-data">
          <h5>Links</h5>
          <ul>
            <li>
              <i class="bx bx-right-arrow-alt"></i> &nbsp;<router-link to="/"
                >Home</router-link
              >
            </li>
            <li>
              <i class="bx bx-right-arrow-alt"></i> &nbsp;<router-link
                to="/projects"
                >Projects</router-link
              >
            </li>
            <li>
              <i class="bx bx-right-arrow-alt"></i> &nbsp;<router-link
                to="/about-us"
                >about us</router-link
              >
            </li>
            <li>
              <i class="bx bx-right-arrow-alt"></i> &nbsp;<router-link
                to="/contact-us"
                >contact us</router-link
              >
            </li>
          </ul>
        </div>
        <div class="col-lg-4 col-md-12 py-5 footer-data">
          <h5>Quick Contact</h5>
          <p class="contact-details">
            No.23 Thilinapura, Thalangala,<br />
            Gonapola Junction, Sri Lanka.
          </p>
          <a href="mailto:arsconstructionspvtltd@gmail.com" class="h6"
            >arsconstructionspvtltd@gmail.com</a
          >
          <a href="tel:+94777707689" class="h6"> (94) 77 7707 689</a>
          <p class="contact-details">
            if you need to get some information,don't hesitate to contact us;
            and we hope to do our best for your bussiness
          </p>
        </div>
      </div>
    </div>

    <div class="row bottom py-5">
      <div class="w-100">
        <div class="container">
          <div class="row pb-5">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <p class="types">
                FABRICATION AND ERECTION OF ALL STEEL STRUCTURES AND BUILDINGS |
                STRUCTURE FACTORY | WAREHOUSE &amp; STORAGE | ROOFING | CLADDING
                | ROOF PLUMBING |INTERNAL MEZZANINE FLOOR DECKS | HIRING CRANE |
                FORKLIFT | BOOM TRUCK | MACHINES | DEC LORRY
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-4 col-sm-12 copy">
              <p>
                2021 - {{ new Date().getFullYear() }} Developed By
                <a href="https://virtuelsolutions.com/"> virtuel solutions</a>
              </p>
            </div>
            <div class="col-lg-6 col-md-4 col-sm-12 social">
              <ul class="social-list">
                <li>
                  <a href="#!"><i class="bx bxl-facebook"></i></a>
                </li>
                <li>
                  <a href="#!"><i class="bx bxl-twitter"></i></a>
                </li>
                <li>
                  <a href="#!"><i class="bx bxl-linkedin-square"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
footer {
  margin-top: 10px;
  width: 100vw;
  height: auto;
  background: var(--color-primary);
  overflow: hidden;
  .footer-data {
    h5 {
      color: var(--color-secondary);
      margin-bottom: 10px;
    }
    p {
      padding: 0 5rem 0 0;
      color: #ffff;
      font-size: 14px;
      &.contact-details {
        padding: 0 1rem 0 0;
      }
    }
    a {
      text-decoration: none;
      color: #ffff;
      display: block;
      margin: 1rem 0;
    }
    ul {
      list-style: none;
      margin-top: 10px;
      li {
        margin: 10px 0;
        i {
          color: var(--color-secondary);
        }

        a {
          text-decoration: none;
          color: #ffff;
          text-transform: capitalize;
          display: contents;
        }
      }
    }
  }
  .bottom {
    background: var(--footer-color-secondary);
    p.types {
      color: #ffffff;
      text-align: center;
    }
    .copy {
      p {
        text-align: center;
        color: #394f76;
        a {
          color: var(--color-secondary);
          text-decoration: none;
        }
      }
    }

    .social {
      display: flex;
      align-items: center;
      justify-content: center;
      .social-list {
        display: block;
        list-style: none;
        li {
          display: inline-block;
          padding: 0.3rem;
          border: 1px solid #ffffff;
          border-radius: 50%;
          margin: 0 10px;
          a {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            i {
              font-size: 18px;
              color: #ffff;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .bottom {
      .copy {
        p {
          text-align: center;
          span {
            color: var(--color-secondary);
          }
        }
      }

      .social {
        display: flex;
        align-items: center;
        justify-content: center;
        .social-list {
          display: block;
          list-style: none;
          li {
            display: inline-block;
            padding: 0.3rem;
            border: 1px solid #ffffff;
            border-radius: 50%;
            margin: 0 10px;
            a {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              text-decoration: none;
              i {
                font-size: 18px;
                color: #ffff;
              }
            }
          }
        }
      }
    }
  }
}
</style>
