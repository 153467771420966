<template>
  <div class="container-fluid">
    <div class="container gallery py-5">
      <div
        class="image__wrapper"
        v-for="image in gallery"
        :key="image"
        
      >
        <img v-lazy="image" />
      </div>
    </div>
  </div>
</template>

<script>
import Gallery from "@/assets/data/gallery.json";
export default {
  data() {
    return {
      gallery: [],
    };
  },
  methods: {
    setGallery() {
      this.gallery = Gallery;
    },
  },
  created() {
    this.setGallery();
  },

};
</script>

<style lang="scss" scoped>
.container-fluid {
  padding: 0;
}
.gallery {
  min-height: 100vh;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-template-rows: auto;
  gap: 1rem;
  .image__wrapper {
    max-width: 100%;
    height: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    transition: 0.5s ease-in-out;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    &:hover {
      img {
        transition: all 0.5s ease-in-out;
        transform: scale(1.2);
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
