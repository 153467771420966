<template>
  <div class="event-card">
    <router-link  :to="`/projects?id=${project.id}`">
      <img :src="project.images[0]" alt="" srcset="" />
      <div class="top">
        <h1>{{ project.project }}</h1>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
   props: ["project"],
  
};
</script>

<style lang="scss" scoped>
.event-card {
  max-width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  transition:.5s ease-in-out;

  a {
    width: 100%;
    height: 100%;
    z-index: 3;
    img {
      position: relative;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
      z-index:1;
    }
    div {
      position: absolute;
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .top {
      width: 100%;
      height: auto;
      bottom: 0;
      left: 0;
      h1 {
        width: 80%;
        font-size: 15px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 15px;
        padding: 15px 0;
      }
     
    }
  }
  &:hover{
      img {
        transition: all .5s ease-in-out;
        transform: scale(1.2);
      width: 100%;
      height: 100%;
    }
  }
}
.event-card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  .event-card {
    width: 100%;
    height: 100%;
    a {
      .top {
        h1 {
          width: 80%;
          margin-bottom: 15px;
          padding: 15px 0;
        }
     
      }
    }
  }
  .event-card::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
   background: rgba(0, 0, 0, 0.4);
    overflow: hidden;
  }
}

@media screen and (max-width: 768px) {
  .event-card {
    a {
      .top {
        h1 {
          width: 95%;
          font-size: 14px;
        }
        
      }
    }
  }
}
</style>
