<template>
  <nav class="nav container-fluid" :class="state.open ? 'active' : ''">
    <div class="container">
      <ul>
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/projects">Projects</router-link></li>
        <li><router-link to="/gallery">Gallery</router-link></li>
        <li><router-link to="/about-us">about us</router-link></li>
        <li><router-link to="/contact-us">contact us</router-link></li>
      </ul>
      <div
        class="hamburger"
        :class="state.open ? 'active' : ''"
        @click="state.open = !state.open"
      >
        <div class="bar"></div>
      </div>
    </div>
  </nav>
</template>

<script>
import { reactive } from "vue";
export default {
  setup() {
    const state = reactive({
      open: false,
    });
    return { state };
  },
};
</script>

<style lang="scss" scoped>
nav {
  width: 100vw;
  height: 60px;
  background: var(--color-primary);
  overflow: hidden;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    transition: all 1s ease-in-out;
    ul {
      list-style: none;
      display: flex;
      align-items: center;
      align-self: center;
      height: 100%;
      padding-top: 10px;
      transition: all 0.5s ease-in-out;
      li {
        display: block;
        margin: 0 10px;

        a {
          text-decoration: none;
          color: #ffff;
          text-transform: capitalize;
          font-weight: 600;
          height: 100%;

          &.router-link-active,
          &:hover {
            color: var(--color-secondary);
          }
        }
      }
    }
    .hamburger {
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      position: relative;
      top: 0;
      cursor: pointer;
      display: none;
      .bar {
        width: 90%;
        height: 3px;
        border-radius: 10;
        background: var(--color-secondary);
        transition: all 0.5s ease-in-out;
      }
      &::after {
        content: "";
        position: absolute;
        top: 5px;
        width: 90%;
        height: 3px;
        border-radius: 10;
        background: var(--color-secondary);
        transition: all 0.5s ease-in-out;
      }
      &::before {
        content: "";
        position: absolute;
        bottom: 5px;
        width: 90%;
        height: 3px;
        border-radius: 10;
        background: var(--color-secondary);
        transition: all 0.5s ease-in-out;
      }
      &.active {
        .bar {
          width: 0%;
          height: 0px;
          transition: all 0.5s ease-in-out;
        }
        &::after {
          top: 50%;
          transform: rotate(45deg);
          transition: all 0.5s ease-in-out;
        }
        &::before {
          transform: rotate(-45deg);
          bottom: 40%;
          background: var(--color-secondary);
          transition: all 0.5s ease-in-out;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .container {
      flex-direction: row;
      justify-content: flex-end;
      transition: all 1s ease-in-out;
      ul {
        display: none;
      }
      .hamburger {
        display: flex;
        align-self: start;
        margin-top: .5rem;
      }
    }
    &.active {
      transition: all 1s ease-in-out;
      width: 100vw;
      height: auto;
      background: var(--color-primary);
      overflow: hidden;
      .container {
        display: flex;
        align-items: center;
        transition: all 1s ease-in-out;
        ul {
          transition: all 1s ease-in-out;
          height: 100%;
          width: 100%;
          flex-direction: column;
          display: flex;

          li {
            margin: 15px 0;
          }
        }
      }
    }
  }
}
</style>
